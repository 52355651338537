import type {
	JSX,
	Context,
	Signal,
	Component,
	ParentComponent,
	Accessor,
	Setter,
} from "solid-js";
import {
	Show,
	onMount,
	createContext,
	useContext,
	createSignal,
	createEffect,
} from "solid-js";
import { createEmitter, type Emitter } from "@solid-primitives/event-bus";

import clsx from "clsx";

import StepContentCollapse from "./Collapse";
import { CircularProgress, Backdrop, DialogActions } from "@suid/material";

import {
	StepLabelContainer,
	StepContainer,
	StepperContentContainer,
	StepConnector,
	StepConnectorLine,
	StepLabel,
	StepLabelIcon,
	StepContent,
	StepIcon,
	StepperContainer,
} from "./styles/Stepper";

type StepperDirection = "vertical" | "horizontal";

export type NextEvent = { page: number };
type EmitterMap = { next: NextEvent };

export type StepperAction = (
	stepper: StepperInstance,
	index: number,
) => Array<JSX.Element> | JSX.Element;

type StepProps = {
	readonly caption: string;
	readonly name: string;
	readonly form?: Accessor<{
		[key: string]: string;
	}>;
	children: StepperAction;
};

type StepperProps = { direction?: StepperDirection };

export type StepperInstance = {
	steps: Accessor<Array<StepProps>>;
	setSteps: Setter<Array<StepProps>>;
	direction: Accessor<StepperDirection>;
	setDirection: Setter<StepperDirection>;
	emitter: Emitter<EmitterMap>;
	index: Accessor<number>;
	setIndex: Setter<number>;
	working: Accessor<boolean>;
	setWorking: Setter<boolean>;
};

let StepperProvider: Context<StepperInstance>;

export const Step: Component<StepProps> = (props) => {
	const self: StepperInstance = useContext<StepperInstance>(StepperProvider);
	const [index, setIndex]: Signal<number> = createSignal<number>(0);

	const [active, setActive]: Signal<boolean> = createSignal<boolean>(false);

	onMount(
		() => (
			setIndex(self.steps().push(props) - 1),
			createEffect(() => setActive(self.index() === index()))
		),
	);

	return (
		<>
			<StepContainer
				class={clsx({
					"Mui-active": active(),
				})}
			>
				<StepLabel>
					<StepLabelIcon>
						<StepIcon>{index() + 1}</StepIcon>
					</StepLabelIcon>
					<StepLabelContainer>
						<StepLabel>{props.caption}</StepLabel>
					</StepLabelContainer>
				</StepLabel>

				<StepContent>
					<StepperContentContainer>
						<StepContentCollapse
							collapsed={() => !active()}
							direction={self.direction()}
						>
							{props.children(self, index())}
						</StepContentCollapse>
					</StepperContentContainer>
				</StepContent>
			</StepContainer>
			{self.index() < self.steps().length - 1 && (
				<StepConnector>
					<StepConnectorLine></StepConnectorLine>
				</StepConnector>
			)}
		</>
	);
};

type StepperActionType = {
	children?: (
		instance: StepperInstance,
		page: number,
		pages: number,
	) => JSX.Element;
};

export const StepperActions: Component<StepperActionType> = (props) => {
	const self: StepperInstance = useContext<StepperInstance>(StepperProvider);
	const [show, setShow]: Signal<boolean> = createSignal<boolean>(true);

	createEffect(() =>
		setShow(self.index() >= 0 && self.index() < self.steps().length - 1),
	);

	return (
		<Show when={show()}>
			<DialogActions>
				{props.children?.(self, self.index(), self.steps().length - 1)}
			</DialogActions>
		</Show>
	);
};

export const Stepper: ParentComponent<StepperProps> = (props) => {
	const [steps, setSteps]: Signal<Array<StepProps>> = createSignal<
		Array<StepProps>
	>([]);

	const [direction, setDirection]: Signal<StepperDirection> =
		createSignal<StepperDirection>(props.direction || "horizontal");

	const [index, setIndex]: Signal<number> = createSignal<number>(0);
	const [working, setWorking]: Signal<boolean> = createSignal<boolean>(false);

	const emitter = createEmitter<EmitterMap>();

	const instance: StepperInstance = {
		emitter,
		index,
		setIndex,
		working,
		setWorking,
		direction,
		setDirection,
		steps,
		setSteps,
	};

	StepperProvider = createContext<StepperInstance>(instance);

	return (
		<StepperProvider.Provider value={instance}>
			<StepperContainer>
				<Backdrop
					sx={{
						color: "white",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={working()}
				>
					<CircularProgress color="secondary" />
				</Backdrop>
				{props.children}
			</StepperContainer>
		</StepperProvider.Provider>
	);
};
