import type { ParentComponent } from "solid-js";
import { Grid } from "@suid/material";
import { Headline as HeadlineBase } from "../../../forms/Care4You/hipaa/styles";

const Headline: ParentComponent = (props) => (
	<Grid xs={12} spacing={1} container item>
		<HeadlineBase variant="h5">{props.children}</HeadlineBase>
	</Grid>
);

export default Headline;
