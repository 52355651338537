import 'solid-devtools/setup';
import type { Component } from "@care4youapp/shared";
import { render } from "solid-js/web";
import { createInertiaApp } from "@auaust/inertia-adapter-solid";
import { ThemeProvider, CssBaseline } from "@suid/material";
import { ServiceRegistry } from "solid-services";
import RegistrationDlg from "@care4youapp/shared/ts/services/RegistrationDialog";
import theme from "@care4youapp/shared/ts/app/theme";
import Layout from "layout";
import './echo';

createInertiaApp({
	resolve: async (view: string) => {
		const name: string = view.substring(view.lastIndexOf(":") + 1);
		const pages = import.meta.glob("./Pages/**/*.tsx", { eager: true });

		const page: Component = pages[`./Pages/${name}.tsx`] as Component;
		page.default.layout = (page.default && page.default.layout) ?? Layout;

		return page;
	},
	setup({ el, App, props }) {
		console.log({ props });

		render(
			() => (
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<ServiceRegistry expose={[RegistrationDlg]}>
						<Layout>
							<App {...props} />
						</Layout>
					</ServiceRegistry>
				</ThemeProvider>
			),
			el,
		);
	},
});
