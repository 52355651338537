import type { Component, Accessor } from "solid-js";
import { Grid } from "@suid/material";

import HipaaSection1 from "./section1";
import HipaaSection2 from "./section2";
import HipaaSection3 from "./section3";
import HipaaSection4 from "./section4";
import HipaaSection5 from "./section5";

import type { PersonalForm } from "../../../../forms/Care4You/Personal";
import type { LocationForm } from "../../../../forms/Care4You/Location";
import type { ContactType as ContactForm } from "../../../../forms/Care4You/Contact";

type Props = {
	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
};

const HipaaContainer: Component<Props> = (props) => (
	<Grid spacing={3} container>
		<HipaaSection1 form={props.form} />
		<HipaaSection2 form={props.form} />
		<HipaaSection3 form={props.form} />
		<HipaaSection4 form={props.form} />
		<HipaaSection5 form={props.form} />
	</Grid>
);

export default HipaaContainer;
