import logo from '../assets/care4you-logo-blue.png';

type Service = { caption: string, href: string, icon: string; };
type Content = { brand: string; logo: string; services: Array<Service>; };

const content: Content = {
	brand: 'Care4You',
	logo,
	services: [
		{ caption: "Physician Telehealth", href: '', icon: "local_hospital" },
		{ caption: "Diabetic Supplies", href: '', icon: "glucose" },
		{ caption: "Prescription Drugs", href: '', icon: "local_pharmacy" },
		{ caption: "Lab Tests", href: '', icon: "labs" },
		{ caption: "Sleep Health", href: '', icon: "hotel" },
		{ caption: "Behavioral Health", href: '', icon: "psychology" }
	]
};

export default content;
