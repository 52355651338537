import {
	createSignal,
	type Component,
	type Signal,
	type Setter,
	Accessor,
} from "solid-js";
import {
	gretch as HttpRequest,
	type GretchResponse as HttpResponse,
} from "gretchen";

import {
	DialogActions,
	Typography,
	TextField,
	DialogContent,
	AlertTitle,
	Alert,
	Grid,
	DialogContentText,
	Button,
} from "@suid/material";

import type {
	AuthResponse,
	BadAuthResponse,
} from "../../forms/Care4You/Welcome";
type Props = {
	message: string;
	response: Accessor<AuthResponse>;
	setResponse: Setter<AuthResponse>;
};

type Response = HttpResponse<AuthResponse, BadAuthResponse>;

const AccessCodeForm: Component<Props> = (props) => {
	const [value, setValue]: Signal<string> = createSignal<string>("");

	const onClick = () =>
		HttpRequest<AuthResponse, BadAuthResponse>(
			"/api/v1/organization/auth",
			{
				method: "POST",
				json: {
					code: value(),
				},
			},
		)
			.json()
			.then(({ data, error }: Response) =>
				props.setResponse(data || error),
			);

	return (
		<>
			<DialogContent
				sx={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
				dividers
			>
				<DialogContentText
					sx={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
				>
					<Alert severity="warning">
						<AlertTitle>Restricted Access</AlertTitle>
						{props.message}
					</Alert>
					<Grid px={2} pt={2} spacing={2} container>
						<Grid xs={12} item>
							<Typography
								fontSize={21}
								fontWeight={600}
								textAlign="justify"
							>
								Please enter the Access Code provided to you by
								your group, association or employer:
							</Typography>
						</Grid>
						<Grid mb={3} xs={12} item>
							<TextField
								variant="filled"
								label="Access Code"
								error={
									props.response().status === "error" &&
									(props.response() as BadAuthResponse)
										.message.length > 0
								}
								helperText={
									props.response().status === "error" &&
									(props.response() as BadAuthResponse)
										.message
								}
								value={value()}
								onChange={(ev) =>
									setValue(ev.currentTarget.value)
								}
								fullWidth
							/>
						</Grid>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClick}
					variant="contained"
					size="large"
					fullWidth
				>
					Verify Access Code
				</Button>
			</DialogActions>
		</>
	);
};

export default AccessCodeForm;
