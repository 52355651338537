import type { JSX, Component, Accessor, Setter } from "solid-js";
import CloseIcon from "@suid/icons-material/Close";
import {
	Toolbar,
	Slide,
	DialogContentText,
	Dialog,
	IconButton,
	DialogContent,
	AppBar,
} from "@suid/material";

import { TransitionProps } from "@suid/material/transitions";
import type { SessionCreated } from "../forms/Care4You/Welcome";

import type { PersonalForm } from "../forms/Care4You/Personal";
import type { LocationForm } from "../forms/Care4You/Location";
import type { ContactType as ContactForm } from "../forms/Care4You/Contact";

import HipaaForm from "../forms/Care4You/hipaa";
import Paragraph from "../components/styles/Paragraph";

export type Props = {
	session: Accessor<SessionCreated>;

	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
	open: Accessor<boolean>;
	onClose: Setter<boolean>;
};

const Transition: Component<{ children: JSX.Element } & TransitionProps> = (
	props,
) => <Slide direction="up" {...props} />;

const ConsentDialog: Component<Props> = (props) => (
	<Dialog
		open={props.open()}
		onClose={() => props.onClose(false)}
		TransitionComponent={Transition}
		fullScreen
	>
		<AppBar sx={{ position: "relative" }}>
			<Toolbar>
				<Paragraph sx={{ ml: 2, flex: 1 }} variant="h6">
					HIPAA Consent Form
				</Paragraph>
				<IconButton
					edge="start"
					color="inherit"
					onClick={() => props.onClose(false)}
				>
					<CloseIcon />
				</IconButton>
			</Toolbar>
		</AppBar>
		<DialogContent>
			<DialogContentText sx={{ px: 0 }}>
				<HipaaForm session={props.session} form={props.form} />
			</DialogContentText>
		</DialogContent>
	</Dialog>
);

export default ConsentDialog;
