import type { ParentComponent, Accessor } from 'solid-js';
import { Fade, Grow } from '@suid/material';
import { Show } from 'solid-js';
import { Motion } from 'solid-motionone';
import styled from "@suid/material/styles/styled";

export type CollapseDrection = "vertical" | "horizontal";
type Props = { collapsed: Accessor<boolean>; direction: CollapseDrection; };

const Collapse = styled( Motion.div, {
	name: 'MuiCollapse'
} )( {} );

const CollapseBody = styled( 'div', {
	name: 'MuiCollapseBody',
} )( {} );

const CollapseContent = styled( 'div', {
	name: 'MuiCollapseContent',
} )( {} );

const StepContentCollapse: ParentComponent<Props> = ( props ) => (
	<Collapse
		initial={ {
			height: ( !props.collapsed() && 0 ) || 'auto',
			opacity: 0,
		} }
		animate={ {
			height: "auto",
			opacity: 1,
			transition: {
				height: {
					duration: 0.4,
				},
				opacity: {
					duration: 0.25,
					delay: 0.15,
				},
			},
		} }
		exit={ {
			height: 0,
			opacity: 0,
			transition: {
				height: {
					duration: 0.4,
				},
				opacity: {
					duration: 0.25,
				},
			},
		} }	>
		<Show when={ !props.collapsed() }>
			<Fade in={ props.collapsed() }>
				<Grow in={ !props.collapsed() }>
					<CollapseBody>
						<CollapseContent>
							{ props.children }
						</CollapseContent>
					</CollapseBody>
				</Grow>
			</Fade>
		</Show>

	</Collapse>
);

export default StepContentCollapse;