type Specifiers = { 's': string, 'd': number, 'b': boolean, 'D': Date; };

type Values<T extends string>
	= T extends `${ infer _ }%${ infer K }${ infer Rest }`
	? K extends keyof Specifiers
	? [ Specifiers[ K ], ...Values<Rest> ]
	: Values<`${ K }${ Rest }`>
	: [];

type Formatted<T extends string>
	= T extends `${ infer Head }%${ infer K }${ infer Tail }`
	? K extends keyof Specifiers
	? `${ Head }${ string }${ Formatted<Tail> }`
	: `${ Head }%${ Formatted<`${ K }${ Tail }`> }`
	: T;

export const StringFmt = <T extends string = string,> ( fmt: T ) => ( {
	format: ( ...args: Values<T> ): Formatted<T> => (
		fmt.match( /(\%[a-zA-Z0-9])/g ) || [] as Array<T>
		// @ts-expect-error
	).reduce( ( buffer: T, token: T, index: number ): T =>
		buffer.replace( token, args[ index ] ) as T,
		fmt
	) as Formatted<T>
} );
