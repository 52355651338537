import { Typography } from "@suid/material";
import styled from "@suid/material/styles/styled";

type StyledTypography = typeof Typography;

export const Subject: StyledTypography =
	styled( Typography )( { fontWeight: 800, display: 'inline' } );

export const Headline: StyledTypography = styled( Typography )( {
	fontFamily: '"Crimson Text", Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif',
	fontWeight: 600
} );

export const Paragraph: StyledTypography = styled( Typography )( {
	textAlign: 'justify',
} );
