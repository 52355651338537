import type { ParentComponent, Accessor } from "solid-js";
import styled from "@suid/material/styles/styled";
import { SvgIcon } from "@suid/material";

import type { StyledComponent } from "@suid/system/createStyled";
import type { OverridableComponent } from "@suid/types";
import { SvgIconTypeMap } from "@suid/material/SvgIcon/SvgIconProps";

type StepperIconProps = {
	active?: Accessor<boolean>;
};

type StyledDiv = StyledComponent<"div", unknown>;
type StyledSvgIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">>;

export const StepperContainer: StyledDiv = styled("div", {
	name: "MuiStepper",
})({
	position: "relative",
	overflow: "hidden",
	counterReset: "section",
	overflowY: "auto",
	overflowX: "hidden",
	display: "flex",
	flexDirection: "column",
});

export const StepContainer = styled("div", {
	name: "MuiStep",
})(
	({
		theme: {
			typography,
			palette,
			transitions: { easing },
			transitions,
		},
	}) => ({
		flex: "0 0 100%",
		position: "relative",

		"& .MuiStepLabelIconContainer-root": {
			color: palette.grey[600],
		},

		"& .MuiStepLabel-root": {
			color: palette.grey[600],
		},

		"&.Mui-active": {
			color: "black",

			"& .MuiStepLabelIconContainer-root": {
				color: palette.primary.main,
			},

			"& .MuiStepLabel-root": {
				fontWeight: typography.fontWeightBold,
				color: "black",
			},
		},
		transition: transitions.create(["height", "padding-bottom"], {
			duration: "400ms",
			easing: easing.easeInOut,
		}),
	}),
);

export const StepperContentContainer = styled("div", {
	name: "MuiStepperContainer",
})(
	({
		theme: {
			transitions: { easing },
			transitions,
		},
	}) => ({
		display: "flex",
		flexDirection: "column",
		transition: transitions.create("transform", {
			duration: "0.3s",
			easing: easing.easeInOut,
		}),
	}),
);

export const StepContent = styled("div", {
	name: "MuiStepContent",
})(
	({
		theme: {
			transitions: { easing },
			transitions,
		},
	}) => ({
		marginLeft: "15px",
		paddingLeft: "20px",
		paddingRight: "8px",
		borderLeft: "1px solid rgb(117, 117, 117)",

		transition: transitions.create("transform", {
			duration: "0.3s",
			easing: easing.easeInOut,
		}),
	}),
);

export const StepLabelContainer = styled("span", {
	name: "MuiStepLabelContainer",
})(({ theme: { palette } }) => ({
	display: "flex",
	alignItems: "center",
	textAlign: "left",
	padding: "0 0 8px 0",
	width: "100%",
	lineHeight: "3rem",
	color: palette.grey[600],
}));

export const StepLabelIcon = styled("span", {
	name: "MuiStepLabelIconContainer",
})({
	display: "flex",
	alignItems: "center",
	textAlign: "left",
	marginRight: "8px",
	paddingLeft: "5px",
});

export const StepConnector = styled("div", {
	name: "MuiStepConnector",
})({
	flex: "1 1 auto",
	display: "flex",
	flexDirection: "row",
	marginLeft: "15px",
	minHeight: "24px",
	"& .MuiStepConnectorLine-root": {
		borderLeft: "1px solid rgb(117, 117, 117)",
		minHeight: "24px",
	},
});

export const StepConnectorLine = styled("span", {
	name: "MuiStepConnectorLine",
})({
	display: "block",
});

export const StepLabel = styled("span", {
	name: "MuiStepLabel",
})(({ theme: { typography, palette } }) => ({
	width: "100%",
	height: "2.5rem",
	fontWeight: typography.fontWeightMedium,
	color: palette.grey[600],
	display: "flex",
}));

export const StepperNavContainer = styled("div", {
	name: "MuiStepperNavContainer",
})({
	display: "flex",
	justifyContent: "space-between",
	marginTop: "20px",
});

export const SvgIconWrapper: StyledSvgIcon = styled(SvgIcon, {
	name: "MuiSvgIconWrapper",
})(
	({
		theme: {
			transitions,
			transitions: { easing },
		},
	}) => ({
		width: "25px",
		height: "25px",

		userSelect: "none",
		flexShrink: 0,

		fill: "currentcolor",
		fontSize: "0.75rem",

		transition: transitions.create("color", {
			delay: "150ms",
			easing: easing.easeOut,
		}),
	}),
);

export const Text = styled("text", {
	name: "MuiSvgIconText",
})(
	({
		theme: {
			transitions,
			transitions: { easing },
		},
	}) => ({
		width: "100%",
		color: "rgba(255, 255, 255, 0.7)",

		transition: transitions.create("color", {
			delay: "150ms",
			easing: easing.easeOut,
		}),
	}),
);

export const StepIcon: ParentComponent<StepperIconProps> = (props) => (
	<SvgIconWrapper aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
		<circle cx={12} cy={12} r={12}>
			{" "}
		</circle>
		<Text x={12} y={12} text-anchor="middle" dominant-baseline="central">
			{props.children}
		</Text>
	</SvgIconWrapper>
);
