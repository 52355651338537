import type { Component } from "solid-js";
import { useService } from "solid-services";
import { Paper, Grid, Button } from "@suid/material";
import { Paragraph, HeroBox, GridContainer, Title } from "styles";
import type { SiteTheme } from "@care4youapp/shared/ts/providers/ThemeProvider";

import PricingDlg from "@care4youapp/shared/ts/Dialogs/pricing";
import RegistrationDlg from "@care4youapp/shared/ts/services/RegistrationDialog";
import DrugPricingService from "@care4youapp/shared/ts/services/DrugPricingService";

type Props = { theme: SiteTheme };
const CoverPage: Component<Props> = (props) => {
	const dialog = useService(RegistrationDlg);
	const usePricingService = useService(DrugPricingService);
	const pricingSvc = usePricingService();

	return (
		<PricingDlg>
			<HeroBox id="home" component="main">
				<GridContainer spacing={2} margin={2} container>
					<Grid
						xs={12}
						md={9}
						sx={{
							mb: { xs: "0 !important", md: "1rem !important" },
						}}
						item
					>
						<Paper
							sx={{
								color: "white",
								backgroundColor: "rgba(0, 0, 0, 0.7)",
								padding: 2,
							}}
							elevation={3}
						>
							<Title>CARE4YOU HEALTH REGISTRATION</Title>

							<Paragraph gutterBottom>
								Welcome to the program registration page for
								Care4You Health's diabetes management program,
								your smart choice for diabetes care. The
								information you provide below will be used
								administratively. All information is treated as
								confidential and is accessible only to
								authorized Program providers. For clinical
								purposes, as you visit each network provider,
								you will be asked to provide additional basic
								information specific to the care they provide.
								For security purposes, you may also be asked to
								confirm your personal information or provide
								additional information for your medical record.
							</Paragraph>

							<Paragraph gutterBottom>
								Care4You is designed to provide you with access
								to high-quality health care, at the most
								affordable price. You may use the pharmacy of
								your choice, however many patients choose to use
								Elixir mail order pharmacy for best service and
								pricing.
							</Paragraph>

							<Paragraph gutterBottom>
								To get started, click or tap the button below.
							</Paragraph>

							<Button
								variant="contained"
								onClick={dialog().setDialog(true)}
								fullWidth
							>
								Register Today
							</Button>
						</Paper>
					</Grid>
				</GridContainer>
			</HeroBox>
		</PricingDlg>
	);
};

export default CoverPage;
