import { type Component } from "solid-js";
import type { SubmitHandler } from "@care4youapp/shared/ts/forms/ShopifyAuth";
import type { SiteTheme } from "@care4youapp/shared/ts/providers/ThemeProvider";
import { useService } from "solid-services";
import ShopifyAuthRequest, {
	type ShopifyAuthResponse,
} from "@care4youapp/shared/ts/services/ShopifyAuthRequest";
import RegistrationDlg from "@care4youapp/shared/ts/services/RegistrationDialog";
import Register from "@care4youapp/shared/ts/Dialogs/register";
import themeMeta from "../brand";
import CoverPage from "../CoverPage";

import "../../css/index.scss";

const Index: Component = () => {
	const theme: SiteTheme = themeMeta as SiteTheme;
	const shopify = useService(ShopifyAuthRequest);

	const client = shopify();
	const registration = useService(RegistrationDlg);

	const dialog = registration();

	/**
	 * Submit form handler
	 *
	 * @param open 			boolean
	 * @returns
	 */
	const submit: SubmitHandler = (form) => (
		dialog.setBusy(true),
		client
			.post(form)
			.then((response: ShopifyAuthResponse) => (
				dialog.setBusy(false), response
			))
			.then(({ message: password = undefined }: ShopifyAuthResponse) =>
				window.location.href.localeCompare(client.url) !== 0 &&
				password === undefined &&
				window.location.replace(client.url),
			));

	return (
		<>
			<CoverPage theme={theme} />
			<Register submit={submit} theme={theme} />
		</>
	);
};

export default Index;
