import { Grid, Typography, Box } from "@suid/material";
import Paragraph from "@care4youapp/shared/ts/components/styles/Paragraph";
import styled from "@suid/material/styles/styled";
import { blueGrey } from "@suid/material/colors";

type StyledTypography = typeof Typography;
type StyledGrid = typeof Grid;
type StyledBox = typeof Box;

const fromAssetUrl = ( assetPath: string ): string =>
	'url("https://images.pexels.com/photos/'.concat( assetPath.concat( '")' ) );

export const HeroBox: StyledBox = styled( Box )( {
	width: '100%',
	display: 'flex',
	minHeight: 'calc(100vh - 5.5rem)',
	alignItems: 'stretch',
	justifyContent: 'stretch',
	backgroundImage: fromAssetUrl( '4266932/pexels-photo-4266932.jpeg' ),
	backgroundSize: 'cover'
} );

export const GridContainer: StyledGrid = styled( Grid )( ( { theme } ) => ( {
	display: "flex",
	padding: "3.5% 50px 0 50px",

	color: blueGrey[ 50 ],
	alignContent: "end",

	'& .MuiGrid-item': {
		marginBottom: '3rem',
		color: 'black',

		[ theme.breakpoints.down( 'sm' ) ]: {
			marginBottom: '0',
		},
	}
} ) );

export const Title: StyledTypography = styled( Typography )( ( { theme } ) => ( {
	textAlign: 'center',
	fontSize: '2rem',

	[ theme.breakpoints.down( 'sm' ) ]: {
		fontSize: '1.75rem'
	},

	[ theme.breakpoints.up( 'lg' ) ]: {
		fontSize: '2.75rem'
	}
} ) );

export const Subtitle: StyledTypography = styled( Typography )( ( {
	textAlign: 'center',
	lineHeight: 2,
	fontSize: '2.25rem'
} ) );

export { Paragraph };
