import type { Component, Accessor, Signal } from "solid-js";
import { Show, createSignal } from "solid-js";
import type { SubmitHandler } from "../forms/ShopifyAuth";
import Paragraph from "../components/styles/Paragraph";
import type { EnrollResponse } from "../forms/Care4You/Consent";
import ConsentForm from "../forms/Care4You/Consent";
import ThankYouForm from "../forms/Care4You/ThankYou";
import PaymentForm from "../forms/Care4You/PaymentForm";
import AccessCodeForm from "../forms/Care4You/AccessCode";
import WelcomePage, {
	type SessionCreated,
	type AuthResponse,
} from "../forms/Care4You/Welcome";
import ContactForm, { type ContactType } from "../forms/Care4You/Contact";
import PersonalForm, {
	type PersonalForm as Personal,
} from "../forms/Care4You/Personal";
import LocationForm, {
	type LocationForm as Location,
} from "../forms/Care4You/Location";

import {
	Dialog,
	LinearProgress,
	IconButton,
	AppBar,
	Toolbar,
	Icon,
	Box,
	Button,
	Grid,
	DialogContent,
	useMediaQuery,
	useTheme,
} from "@suid/material";

import { useService } from "solid-services";
import RegistrationDlg from "../services/RegistrationDialog";
import { Heading } from "./styles";
import BrandLogo from "../components/BrandLogo";
import SiteTheme from "../providers/ThemeProvider";

import { Stepper, StepperActions } from "../components/Stepper";
import ConsentDialog from "./hipaa";

export type Props = { submit: SubmitHandler; theme: SiteTheme };

const Register: Component<Props> = (props) => {
	const dialog = useService(RegistrationDlg);

	const [consentDlg, useConsentDlg]: Signal<boolean> =
		createSignal<boolean>(false);
	const theme = useTheme();

	const isSmall = useMediaQuery(theme.breakpoints.down("md"));
	const [secret, setSecret]: Signal<string> = createSignal<string>("");

	const [authResponse, setAuthResponse]: Signal<AuthResponse> =
		createSignal<AuthResponse>({
			status: "error",
			message: "",
			details: { needPayment: false },
		});

	const [response, setResponse]: Signal<AuthResponse> =
		createSignal<AuthResponse>({
			status: "error",
			message: "",
			details: { needPayment: false },
		});

	const [personal, setPersonal]: Signal<Personal> = createSignal<Personal>({
		first: "Robert",
		middle: "M",
		last: "Butler",
		gender: "Male",
		birthdate: new Date(1983, 9, 30, 0, 0, 0, 0),
	});

	const [location, setLocation]: Signal<Location> = createSignal<Location>({
		address1: "2100 Billmar Lane North",
		address2: "",
		city: "St. Petersburg",
		state: "FL",
		zip: "33714",
	});

	const [contact, setContact]: Signal<ContactType> =
		createSignal<ContactType>({
			password: "Oceanic1LaMar12",
			email: "me@r-butler.net",
			phone: "7276374756",
		});

	const restricted: string =
		"Membership in this program is restricted to".concat(
			" ".concat("authorized employers, groups and associations."),
		);

	return (
		<>
			<Dialog
				fullScreen={isSmall()}
				open={dialog().open}
				maxWidth={(isSmall() && "xl") || "md"}
				scroll="paper"
				fullWidth
			>
				<AppBar
					sx={{
						position: (isSmall() && "fixed") || "relative",
					}}
					color="transparent"
					elevation={0}
				>
					<Toolbar>
						<Heading>
							<Box
								sx={{
									maxWidth: "140px",
									maxHeight: "40px",
									flex: 1,
								}}
							>
								<BrandLogo logo={props.theme.logo} />
							</Box>
						</Heading>
						<IconButton
							onClick={dialog().setDialog(false)}
							edge="start"
							color="inherit"
							aria-label="close"
						>
							<Icon>close</Icon>
						</IconButton>
					</Toolbar>
				</AppBar>
				<LinearProgress
					sx={{ display: (dialog().busy && "inline") || "none" }}
				/>

				<Show
					when={authResponse().status === "success"}
					fallback={
						<AccessCodeForm
							message={restricted}
							response={authResponse}
							setResponse={setAuthResponse}
						/>
					}
				>
					<DialogContent>
						<Stepper>
							<PaymentForm
								session={
									authResponse as unknown as Accessor<SessionCreated>
								}
								setSecret={setSecret}
								secret={secret}
								name="payment"
								form={{
									personal: personal(),
									location: location(),
									contact: contact(),
								}}
								caption={"Payment Information"}
							>
								<Paragraph>
									Now we'll need your phone number and
									email address to ensure we can
									periodically contact you for
									administrative purposes.
								</Paragraph>
							</PaymentForm>

							<ThankYouForm
								session={
									authResponse as unknown as Accessor<SessionCreated>
								}
								response={
									response as unknown as Accessor<EnrollResponse>
								}
							/>
						</Stepper>
					</DialogContent>
				</Show>

				<Show when={authResponse().status === "success"}>
					<StepperActions>
						{({ setIndex, emitter, steps: pages }, index) =>
							(index == 0 && (
								<Button
									onClick={() =>
										emitter.emit("next", {
											page: index + 1,
										})
									}
									variant="contained"
									fullWidth
								>
									Get Started
								</Button>
							)) ||
							(index > 0 && index < pages().length && (
								<Grid spacing={3} container>
									<Grid xs={12} md={6} item>
										<Button
											onClick={() =>
												setIndex((page) => page - 1)
											}
											fullWidth
										>
											Prev
										</Button>
									</Grid>
									<Grid xs={12} md={6} item>
										<Button
											onClick={() =>
												emitter.emit("next", {
													page: index + 1,
												})
											}
											variant="contained"
											fullWidth
										>
											Next
										</Button>
									</Grid>
								</Grid>
							))
						}
					</StepperActions>
				</Show>
			</Dialog>

			<ConsentDialog
				open={consentDlg}
				session={authResponse as Accessor<SessionCreated>}
				form={{ personal, location, contact }}
				onClose={useConsentDlg}
			/>
		</>
	);
};

export default Register;
