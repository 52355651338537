import type { Component, Accessor } from "solid-js";
import { Grid } from "@suid/material";

import { Collection } from "../../../../forms/Care4You/hipaa/list";
import { Paragraph } from "../../../../forms/Care4You/hipaa/styles";
import Headline from "../../../../forms/Care4You/hipaa/headline";

import type { PersonalForm } from "../../../../forms/Care4You/Personal";
import type { LocationForm } from "../../../../forms/Care4You/Location";
import type { ContactType as ContactForm } from "../../../../forms/Care4You/Contact";

type Props = {
	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
};

const HipaaSection2: Component<Props> = () => (
	<>
		<Headline>Section III: Reason For Disclose</Headline>

		<Grid xs={12} px={2}>
			<Paragraph variant="subtitle1">
				Please detail the reason(s) why information is being shared:
			</Paragraph>
		</Grid>
		<Grid xs={12} md={3} px={3}>
			<Collection items={["Per my request"]} bullet />
		</Grid>
	</>
);

export default HipaaSection2;
