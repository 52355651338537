import type { Component, Accessor } from "solid-js";
import { Grid } from "@suid/material";

import { Collection } from "../../../../forms/Care4You/hipaa/list";
import { Paragraph, Subject } from "../../../../forms/Care4You/hipaa/styles";
import Headline from "../../../../forms/Care4You/hipaa/headline";

import type { PersonalForm } from "../../../../forms/Care4You/Personal";
import type { LocationForm } from "../../../../forms/Care4You/Location";
import type { ContactType as ContactForm } from "../../../../forms/Care4You/Contact";

type Props = {
	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
};

const HipaaSection2: Component<Props> = () => (
	<>
		<Headline>
			Section IV: Parties Authorized to Receive My Health Information
		</Headline>

		<Grid xs={12} px={2}>
			I authorize that my PHI be collected, as outlined in{" "}
			<Subject>Section II</Subject> of this document to be shared only
			with the following individual(s) or organization(s):
		</Grid>

		<Grid xs={12} px={4}>
			<Collection
				items={[
					"24Hour Physicians/Healthcare Now Physicians",
					"iHLTH",
					"eHome Counseling",
					"Creative Physicians (D/B/A GPM Services)",
					"any/all successive MedAccess Rx, LLC (D/B/A, Care4You)-contracted network health care providers",
				]}
				last=""
				bullet
			/>
		</Grid>

		<Grid xs={12} px={2}>
			<Paragraph variant="subtitle1">
				In the event that my information has already been shared by the
				time my authorization is revoked, it may be too late to cancel
				permission to share my health data. I also understand that I do
				not need to give any further permission for the information
				detailed in <Subject>Section II</Subject> to be shared with the
				person(s) or organization(s) listed in{" "}
				<Subject>Section IV</Subject>.
			</Paragraph>
		</Grid>

		<Grid xs={12} px={2} mt={2}>
			<Paragraph variant="subtitle1">
				I understand that a failure to sign and submit this
				authorization or the cancellation of this authorization will not
				prevent me from receiving any treatment or benefits I am
				entitled to receive, provided this information is not required
				to determine if I am eligible to receive those treatments or
				benefits or to pay for the services I receive.
			</Paragraph>
		</Grid>
	</>
);

export default HipaaSection2;
