import type { Component, Accessor } from "solid-js";
import { Grid, TextField } from "@suid/material";

import Headline from "../../../../forms/Care4You/hipaa/headline";

import type { PersonalForm } from "../../../../forms/Care4You/Personal";
import type { LocationForm } from "../../../../forms/Care4You/Location";
import type { ContactType as ContactForm } from "../../../../forms/Care4You/Contact";

type Props = {
	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
};

const HipaaSection2: Component<Props> = (props) => (
	<>
		<Headline>Section V: Signature</Headline>

		<Grid xs={12} px={2} container>
			<Grid xs={12} md={6} container>
				<Grid xs={12}>
					<TextField
						variant="standard"
						helperText="Signature"
						value={props.form
							.personal()
							.first?.concat(
								" ".concat(
									(
										props.form.personal().middle || " "
									)?.concat(
										props.form.personal()?.last as string,
									),
								),
							)}
						InputProps={{
							readOnly: true,
						}}
						hiddenLabel
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid
				xs={12}
				md={6}
				sx={{ mt: { xs: 2, md: 0 }, pl: { xs: 0, md: 2 } }}
				container
			>
				<Grid xs={12}>
					<TextField
						variant="standard"
						helperText="Date"
						value={new Date().toLocaleDateString()}
						InputProps={{
							readOnly: true,
						}}
						hiddenLabel
						fullWidth
					/>
				</Grid>
			</Grid>
		</Grid>
	</>
);

export default HipaaSection2;
