import { type ParentProps, type Component, createSignal } from "solid-js";
import { Divider, Button, Menu, MenuItem } from "@suid/material";

import theme from "brand";
import items from "Menu";

import AppBarLogo from "@care4youapp/shared/ts/components/shell/AppBarLogo";
import Header from "@care4youapp/shared/ts/components/shell/Header";

import AppBarMenu from "@care4youapp/shared/ts/components/shell/AppBarMenu";
import Drawer from "@care4youapp/shared/ts/components/shell/Drawer";

import logo from "assets/care4you-logo-blue.png";
import { Layout as LayoutBase } from "@care4youapp/shared/ts/layouts/ShopifyBase";

const Layout: Component<ParentProps> = ({ children }) => {
	const [drawer, setDrawer] = createSignal<boolean>(false);
	const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);

	const open = () => Boolean(anchorEl());
	const handleClose = () => {
		setAnchorEl(null);
	};

	const props = {
		drawer,
		theme,
		items,

		/**
		 * Toggle drawer state.
		 *
		 * @param open 			boolean
		 * @returns
		 */
		toggle(open: boolean): VoidFunction {
			return () => setDrawer(open);
		},
	};

	return (
		<LayoutBase>
			<Header
				drawer={drawer}
				items={items}
				theme={theme}
				toggle={props.toggle}
			>
				<AppBarLogo logo={logo} toggle={props.toggle} />
				<AppBarMenu
					theme={theme}
					items={items}
					slots={{
						button: (
							<>
								<Button
									variant="contained"
									aria-haspopup="true"
									aria-controls={
										open() ? "basic-menu" : undefined
									}
									aria-expanded={open() ? "true" : undefined}
									onClick={(event) => {
										setAnchorEl(event.currentTarget);
									}}
									fullWidth
								>
									{theme.brand} Program Menu
								</Button>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl()}
									open={open()}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "basic-button",
									}}
								>
									<MenuItem onClick={handleClose}>
										New Member Registration
									</MenuItem>
									<MenuItem onClick={handleClose}>
										Drug Pricing
									</MenuItem>

									<Divider />

									<MenuItem onClick={handleClose}>
										Return home
									</MenuItem>
								</Menu>
							</>
						),
					}}
				/>
			</Header>

			<Drawer {...props} />

			{children}
		</LayoutBase>
	);
};

export default Layout;
