import type {
	JSX,
	ParentComponent,
	Component,
	Accessor,
	Signal,
	Setter,
} from "solid-js";
import { createSignal, splitProps } from "solid-js";
import CloseIcon from "@suid/icons-material/Close";
import {
	TextField,
	IconButton,
	Typography,
	Paper,
	AppBar,
	DialogContent,
	Switch,
	FormControlLabel,
	Button,
	Table,
	Fade,
	Toolbar,
	DialogContentText,
	Dialog,
	Grid,
	TableCell,
	TableHead,
	TableBody,
	TableRow,
	DialogActions,
	TableContainer,
	useTheme,
	useMediaQuery,
} from "@suid/material";

import { Property } from "csstype";
import { DataGrid, type Drug, type DrugCost } from "../components/DataGrid";

import { cyan as AlertColors } from "@suid/material/colors";
import styled from "@suid/material/styles/styled";
import { useService } from "solid-services";
import DrugPricingService, {
	type DialogModel,
	type DrugMatches,
} from "../services/DrugPricingService";
import { TransitionProps } from "@suid/material/transitions";
import Paragraph from "../components/styles/Paragraph";

export type Props = { open: Accessor<boolean>; onClose: Setter<boolean> };
type PricingTableProps = { drug: Accessor<DrugCost> };

const Transition: Component<{ children: JSX.Element } & TransitionProps> = (
	props,
) => <Fade appear {...props} />;

const PricingTable: Component<PricingTableProps> = (props) => (
	<TableContainer component={Paper}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell>Drug Name</TableCell>
					<TableCell align="right">Quantity</TableCell>
					<TableCell align="right">Drug Cost</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow
					sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
				>
					<TableCell>{props.drug().DrugName}</TableCell>
					<TableCell align="right">
						{props.drug().params.quantity}
					</TableCell>
					<TableCell align="right">
						{"$".concat(props.drug().cost.value.toString())}
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	</TableContainer>
);

const Alert: ParentComponent = (props) => {
	const AlertPaper = styled(Paper)({
		backgroundColor: AlertColors[400],
		// color: 'white',
		borderRadius: 0,
		paddingTop: 10,
		paddingRight: 30,
		paddingBottom: 10,
		paddingLeft: 30,
	});

	return (
		<AlertPaper>
			<Grid spacing={2} container>
				<Grid xs={12} item>
					{props.children}
				</Grid>
			</Grid>
		</AlertPaper>
	);
};

const GenericLabel: Component<{
	value: Accessor<boolean>;
	label?: string;
	yes?: string;
	no?: string;
}> = (props) => {
	const Label = styled(Typography)(({ theme: { typography } }) => ({
		fontWeight: typography.fontWeightBold,
	}));

	const [
		{
			label = "Prefer to use Generic Drugs?",
			yes = "Yes, use when available",
			no = "No, use only Brand names",
		},
	] = splitProps(props, ["label", "yes", "no"]);

	return (
		<>
			{label} <Label>{(props.value() && yes) || no}.</Label>
		</>
	);
};

type ReponsiveTypography = {
	xs?: Property.Display;
	sm?: Property.Display;
	md?: Property.Display;
	lg?: Property.Display;
	xl?: Property.Display;
};

const ReponsiveTypography: ParentComponent<ReponsiveTypography> = (props) => (
	<Typography
		sx={{
			display: {
				xs: props.xs,
				sm: props.sm,
				md: props.md,
				lg: props.lg,
				xl: props.xl,
			},
		}}
		variant="body2"
		fontStyle="italic"
		textAlign="justify"
	>
		{props.children}
	</Typography>
);

const PricingDlg: ParentComponent = (props) => {
	const usePricingService = useService(DrugPricingService);
	const [generic, setGeneric]: Signal<boolean> = createSignal<boolean>(true);
	const [hasMatch, setHasMatch]: Signal<boolean> =
		createSignal<boolean>(false);
	const [pricing, setPricing]: Signal<boolean> = createSignal<boolean>(false);
	const [matches, setMatches]: Signal<DrugMatches> =
		createSignal<DrugMatches>([]);
	const [cost, setCost]: Signal<DrugCost | undefined> =
		createSignal<DrugCost>();

	const [drugName, setDrugName]: Signal<string> = createSignal<string>("");
	const [quantity, setQuantity]: Signal<string> = createSignal<string>("");
	const { breakpoints } = useTheme();

	const large = useMediaQuery(breakpoints.up("md"));
	const small = useMediaQuery(breakpoints.down("sm"));

	const buttonCaption: string = "Search";
	const [drug, setDrug]: Signal<Drug | undefined> = createSignal<Drug>();

	const [clickOrTap]: Signal<string> = createSignal<string>(
		(small() && "tap") || "click or tap",
	);

	const [model, setModel]: Signal<DialogModel | undefined> =
		createSignal<DialogModel>();
	const pricingSvc = usePricingService();

	return (
		<>
			<Dialog
				open={pricing()}
				TransitionComponent={Transition}
				maxWidth={(large() && "lg") || "sm"}
				fullScreen={small()}
				fullWidth={large()}
			>
				<AppBar sx={{ position: "relative" }}>
					<Toolbar>
						<Paragraph sx={{ ml: 2, flex: 1 }} variant="h6">
							Drug Pricing: {drugName()}
						</Paragraph>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => setPricing(false)}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent sx={{ padding: 0 }}>
					<Grid spacing={2} container>
						<Grid xs={12} container item>
							<PricingTable drug={cost as Accessor<DrugCost>} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<ReponsiveTypography xs="none" md="inherit">
						Drug pricing is updated daily, and every effort is made
						to ensure accuracy. Prices shown on this site are for
						general reference planning purposes only and are not
						guaranteed due to changing market conditions.
					</ReponsiveTypography>
					<Button
						sx={{ display: { md: "none" } }}
						variant="contained"
						fullWidth
					>
						{buttonCaption}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={pricingSvc.isOpen("SearchResults")}
				TransitionComponent={Transition}
				maxWidth={(large() && "lg") || "sm"}
				fullScreen={small()}
				fullWidth={large()}
			>
				<AppBar sx={{ position: "relative" }}>
					<Toolbar>
						<Paragraph sx={{ ml: 2, flex: 1 }} variant="h6">
							Drug Search: {drugName()}
						</Paragraph>
						<IconButton
							edge="start"
							color="inherit"
							onClick={pricingSvc.close("SearchResults")}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent sx={{ padding: 0 }}>
					<Grid spacing={2} container>
						<Grid xs={12} container item>
							<DataGrid
								items={matches}
								onClick={pricingSvc
									.with(drugName, quantity)
									.withModel(setModel)
									.findPrice(setPricing, setCost)}
							>
								Click/tap on a drug shown above to select it.
							</DataGrid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<ReponsiveTypography xs="none" md="inherit">
						Drug pricing is updated daily, and every effort is made
						to ensure accuracy. Prices shown on this site are for
						general reference planning purposes only and are not
						guaranteed due to changing market conditions.
					</ReponsiveTypography>
					<Button
						sx={{ display: { md: "none" } }}
						variant="contained"
						fullWidth
					>
						{buttonCaption}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={pricingSvc.isOpen("PricingDialog")}
				TransitionComponent={Transition}
				maxWidth={(large() && "lg") || "sm"}
				fullScreen={small()}
				fullWidth={large()}
			>
				<AppBar sx={{ position: "relative" }}>
					<Toolbar>
						<Paragraph sx={{ ml: 2, flex: 1 }} variant="h6">
							Online Drug Pricing
						</Paragraph>
						<IconButton
							edge="start"
							color="inherit"
							onClick={pricingSvc.close("PricingDialog")}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Alert>
					<Typography
						variant="subtitle1"
						textAlign="justify"
						lineHeight={1.2}
					>
						For your convenience, you can determine the price of
						your medications. Simply enter the information indicated
						on the form and {clickOrTap()} the "{buttonCaption}"
						button below.
					</Typography>
				</Alert>
				<DialogContent>
					<DialogContentText sx={{ px: 0 }}>
						<Grid spacing={2} container>
							<Grid xs={12} md={10} container item>
								<TextField
									label="Medication"
									variant="filled"
									value={drugName()}
									onChange={(event, value) =>
										setDrugName(value)
									}
									fullWidth
								/>
							</Grid>
							<Grid xs={6} md={2} container item>
								<TextField
									label="Quantity"
									variant="filled"
									value={quantity()}
									onChange={(event, value) =>
										setQuantity(value)
									}
									fullWidth
								/>
							</Grid>
							<Grid
								xs={12}
								sx={{ userSelect: "none" }}
								container
								item
							>
								<FormControlLabel
									control={
										<Switch
											checked={generic()}
											onChange={(event, value) =>
												setGeneric(value)
											}
										/>
									}
									label={<GenericLabel value={generic} />}
								/>
							</Grid>
							<Grid xs={12} container item>
								<ReponsiveTypography md="none">
									Drug pricing is updated daily, and every
									effort is made to ensure accuracy. Prices
									shown on this site are for general reference
									planning purposes only and are not
									guaranteed due to changing market
									conditions.
								</ReponsiveTypography>
								<Button
									sx={{
										display: { xs: "none", md: "inherit" },
									}}
									disabled={
										(drugName().length == 0 &&
											quantity().length == 0) ||
										drugName().length == 0 ||
										quantity().length == 0 ||
										pricingSvc.busy()
									}
									onClick={pricingSvc
										.with(drugName, quantity)
										.withModel(setModel)
										.find(setMatches, setHasMatch)}
									variant="contained"
									fullWidth
								>
									{buttonCaption}
								</Button>
							</Grid>
						</Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<ReponsiveTypography xs="inherit" md="inherit">
						Drug pricing is updated daily, and every effort is made
						to ensure accuracy. Prices shown on this site are for
						general reference planning purposes only and are not
						guaranteed due to changing market conditions.
					</ReponsiveTypography>
					<Button
						sx={{ display: { md: "none" } }}
						disabled={
							(drugName().length == 0 &&
								quantity().length == 0) ||
							drugName().length == 0 ||
							quantity().length == 0 ||
							pricingSvc.busy()
						}
						onClick={pricingSvc
							.with(drugName, quantity)
							.withModel(setModel)
							.find(setMatches, setHasMatch)}
						variant="contained"
						fullWidth
					>
						{buttonCaption}
					</Button>
				</DialogActions>
			</Dialog>

			{props.children}
		</>
	);
};

export default PricingDlg;
