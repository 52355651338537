import moment, { type Moment } from "moment";
import type { ParentComponent, Component, Accessor } from "solid-js";
import { Paper, TextField, Grid, Typography } from "@suid/material";
import { Paragraph, Headline } from "../../../forms/Care4You/hipaa/styles";
import HipaaSections from "../../../forms/Care4You/hipaa/sections";

type SectionProps = { label: string };

import type { PersonalForm } from "../../../forms/Care4You/Personal";
import type { LocationForm } from "../../../forms/Care4You/Location";
import type { SessionCreated } from "../../../forms/Care4You/Welcome";
import type { ContactType as ContactForm } from "../../../forms/Care4You/Contact";

export type Props = {
	session: Accessor<SessionCreated>;

	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
};

const Section: ParentComponent<SectionProps> = (props) => (
	<Grid xs={12} container item>
		<Grid
			xs={12}
			md={3}
			sx={{
				pl: {
					xs: 0,
					md: 2,
				},
				display: "flex",
				justifyContent: "start",
				alignItems: "center",
			}}
			item
		>
			<Typography fontSize={"1.15em"}>
				{props.label.concat(":")}
			</Typography>
		</Grid>
		<Grid xs={12} md={9} item>
			{props.children}
		</Grid>
	</Grid>
);

const HipaaForm: Component<Props> = (props) => {
	const birthdate: Moment = moment(props.form.personal().birthdate).add(
		1,
		"day",
	);

	return (
		<Paper elevation={0}>
			<div
				style={{
					margin: "0 auto",
					"max-width": "903px",
					width: "100%",
				}}
			>
				<Grid spacing={3} sx={{ mb: 4 }} container>
					<Grid
						xs={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						item
					>
						<Headline variant="h4">
							HIPAA Patient Authorization
						</Headline>
					</Grid>

					<Grid xs={12} spacing={1} container item>
						<Grid xs={12} item>
							<Headline variant="h5">
								Care4You Patient Registration
							</Headline>
						</Grid>

						<Section label="Patient's Name">
							<TextField
								name="PatientName"
								variant="filled"
								size="small"
								value={props.form
									.personal()
									.first?.concat(
										" ".concat(
											(
												props.form.personal().middle ||
												" "
											)?.concat(
												props.form.personal()
													?.last as string,
											),
										),
									)}
								InputProps={{
									readOnly: true,
								}}
								hiddenLabel
								fullWidth
							></TextField>
						</Section>

						<Section label="Patient's DOB">
							<TextField
								name="PatientDOB"
								variant="filled"
								size="small"
								value={birthdate.format("M/D/YYYY")}
								InputProps={{
									readOnly: true,
								}}
								hiddenLabel
								fullWidth
							></TextField>
						</Section>

						<Section label="Patient's Gender">
							<TextField
								name="PatientGender"
								variant="filled"
								size="small"
								value={props.form.personal().gender}
								InputProps={{
									readOnly: true,
								}}
								hiddenLabel
								fullWidth
							></TextField>
						</Section>

						<Section label="Patient's Address">
							<TextField
								name="PatientAddress"
								variant="filled"
								size="small"
								value={props.form
									.location()
									.address1.concat(
										((props.form.location().address2 as
											| string
											| undefined) !== undefined &&
											", ".concat(
												props.form.location()
													.address2 as string,
											)) ||
											"",
									)}
								InputProps={{
									readOnly: true,
								}}
								hiddenLabel
								fullWidth
							></TextField>
						</Section>

						<Section label="Patient's Email">
							<TextField
								name="PatientEmail"
								variant="filled"
								size="small"
								value={props.form.contact().email}
								InputProps={{
									readOnly: true,
								}}
								hiddenLabel
								fullWidth
							></TextField>
						</Section>

						<Section label="Patient's Telephone">
							<TextField
								name="PatientTelephone"
								variant="filled"
								size="small"
								value={props.form.contact().phone}
								InputProps={{
									readOnly: true,
								}}
								hiddenLabel
								fullWidth
							></TextField>
						</Section>

						<Section label="Employer/Group/Assoc.">
							<TextField
								name="PatientTelephone"
								variant="filled"
								size="small"
								value={props.session().details.name}
								InputProps={{
									readOnly: true,
								}}
								hiddenLabel
								fullWidth
							></TextField>
						</Section>
					</Grid>

					<Grid xs={12} item>
						<Paragraph variant="subtitle1">
							Please type your name below to authorize treatment
							and to share health information among providers. No
							information will be shared with third parties or
							insurers.
						</Paragraph>
					</Grid>

					<Grid xs={12} item>
						<Paragraph variant="subtitle1">
							Please complete all sections of this HIPAA release
							form. If any sections are left blank, this form will
							be invalid, and it will not be possible for your
							health information to be shared as required by this
							program.
						</Paragraph>
					</Grid>
				</Grid>

				<HipaaSections form={props.form} />
			</div>
		</Paper>
	);
};

export default HipaaForm;
