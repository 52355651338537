import type { Component } from "solid-js";
import { List, ListItem, ListItemText as ListItemTextBase } from "@suid/material";
import styled from "@suid/material/styles/styled";

const ListItemText = styled( ListItemTextBase )( { marginLeft: 17 } );

const withTextFragment = ( value: string, last: string, index: number, length: number ): string => ( length > 1
	&& ( ( ( ( index + 1 ) < length && " " || last.concat( " " ) ).concat(
		value.concat( ( index + 1 ) < length && ", " || " " ) )
	) )
	|| value );

export const Collection: Component<{
	bullet?: boolean,
	plain?: boolean,
	last?: string,
	items: Array<string>;
}> = ( { plain = false, bullet = false, last = "and", items } ) => (
	<List sx={ { pt: 0 } }>
		{ items.map( ( caption, index ) => (
			<ListItem sx={ {
				'&:before': {
					content: "'".concat( ( bullet && "•" || "✓" ).concat( "' " ) )
				}
			} } disablePadding>
				<ListItemText primary={ plain
					&& caption
					|| withTextFragment( caption, last, index, items.length )
				} />
			</ListItem>
		) ) }
	</List>
);