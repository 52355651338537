import type { Accessor, Signal, Setter } from 'solid-js';
import { gretch as HttpRequest } from 'gretchen';

import { createSignal } from 'solid-js';
import type { SearchResult } from '../services/API/Interfaces/DrugSearch';

import type { Drug, DrugCost } from '../components/DataGrid';
export type { Drug } from '../components/DataGrid';

export type DrugMatches = Array<Drug>;
type OnClickEvent<T = void> = ( ev?: MouseEvent ) => T;

type DialogStateProps = {
	[ key: string ]: {
		setInstance?: Setter<DialogModel>,
		getInstance?: Accessor<DialogModel>,
		readonly open: Accessor<boolean>,
		readonly setOpen: Setter<boolean>;
	};
};


/** Drug Not Found */
type NotFoundResult = { code: "not_found"; message: string; };

/** Server Error) */
type RequestFailure = { code: "error"; message: string; };

export type DrugPricingModelServiceInstance<
	KeyType extends ( 'PricingDialog' | 'DrugPricing' | 'SearchResults' ) = (
		'PricingDialog' | 'DrugPricing' | 'SearchResults'
	)
> = {
	readonly busy: Accessor<boolean>,

	/**
	 * Search 'with' drug name and quantity,
	 * @param drug
	 * @param quantity
	 *
	 * @returns DrugPricingModel
	 */
	with ( drug: Accessor<string>, quantity: Accessor<string> ): DialogModel,
	open: ( dialog: KeyType, abort?: boolean ) => VoidFunction,

	isOpen: ( dialog: KeyType, abort?: boolean ) => boolean,
	close: ( dialog: KeyType ) => VoidFunction;
};

type OnClickEventInstance = OnClickEvent<DialogModel>;
type OnDrugSelectCallback = ( drug: Drug ) => unknown;

type Maybe<T> = T | undefined;

export type DialogModel = {
	readonly open: Accessor<boolean>,
	dismiss: VoidFunction,
	find ( setMatches: Setter<DrugMatches>, setHasMatch: Setter<boolean> ): OnClickEventInstance,
	findPrice ( setOpen: Setter<boolean>, setMatch: Setter<Maybe<DrugCost>> ): OnDrugSelectCallback,
	withModel ( setter: Setter<DialogModel | undefined> ): DialogModel;
};

type DialogPricingModelProps = {
	readonly drug: Accessor<string>,
	readonly quantity: Accessor<string>,

	readonly open: Accessor<boolean>,
	parent: DrugPricingModelServiceInstance,

	setOpen: Setter<boolean>,
	setBusy: Setter<boolean>;
};

export type DrugPricingModelConstructor = ( props: DialogPricingModelProps ) => DialogModel;
const createPricingModel: DrugPricingModelConstructor = ( props ) => {
	const instance: DialogModel = {
		get open (): Accessor<boolean> {
			return props.open;
		},

		dismiss (): void {
			props.setOpen( false );
		},

		withModel: ( setter: Setter<DialogModel | undefined> ): DialogModel => setter( instance ),

		findPrice: ( setOpen: Setter<boolean>, setMatch: Setter<Maybe<DrugCost>> ): OnDrugSelectCallback => (
			( { NDC }: Drug ) => {
				props.setBusy( true );
				const form = JSON.stringify( {
					ndc: NDC,
					quantity: props.quantity()
				} );

				type ServerError
					= NotFoundResult
					| RequestFailure;

				const options = { method: "POST", body: form };

				const request = HttpRequest<DrugCost, ServerError>( '/api/v1/pricing/get', options )
					.json();

				request.then( ( { error, response, data: result } ) => {
					if ( !!!error ) {
						setMatch( result );
						setOpen( true );

						props.setOpen( true );
						props.setBusy( false );
					}
					else console.error( { response } );
				} );
			}
		),

		find: ( setMatches ) => ( () => {
			if ( props.parent.busy() === false ) {
				props.setBusy( true );
				const form = JSON.stringify( {
					drug: props.drug(),
					quantity: props.quantity()
				} );

				type ServerError = NotFoundResult | RequestFailure;
				const options = { method: "POST", body: form };

				const request = HttpRequest<SearchResult, ServerError>( '/api/v1/pricing/search', options )
					.json();

				request.then( ( { error, response, data: result } ) => {
					if ( !!!error ) {
						setMatches( result.items || [] );
						props.setOpen( true );
						props.setBusy( false );
					}
					else console.error( { response } );
				} );
			}

			return instance;
		} )
	};

	return instance;
};

const DrugPricingModelService = (): DrugPricingModelServiceInstance => {
	const [ busy, setBusy ]: Signal<boolean> = createSignal<boolean>( false );

	const initPricingDlg = (): DialogStateProps => {
		const [ open, setOpen ]: Signal<boolean> = createSignal<boolean>( false );
		return { 'PricingDialog': { open, setOpen } };
	};

	const [ dialogs, setDialogs ]: Signal<DialogStateProps>
		= createSignal<DialogStateProps>( initPricingDlg() );

	const self: DrugPricingModelServiceInstance = {
		busy,

		with ( drug, quantity ): DialogModel {
			if ( dialogs().hasOwnProperty( 'SearchResults' ) === false ) {
				const [ open, setOpen ]: Signal<boolean> =
					createSignal<boolean>( false );

				const searchModel = createPricingModel( {
					setOpen, open,
					setBusy, drug,
					parent: self,
					quantity,
				} );

				const [ getInstance, setInstance ]: Signal<DialogModel> =
					createSignal<DialogModel>( searchModel );

				setDialogs( ( collection ) => Object.assign( {
					'SearchResults': { getInstance, setInstance, open, setOpen }
				}, collection ) );

				return searchModel;
			}
			else {
				const { [ 'SearchResults' ]: dialog } = dialogs();
				return dialog.getInstance?.() as DialogModel;
			}
		},

		open ( id, abort: boolean = false ): VoidFunction {
			const handler: VoidFunction = () => {
				if ( dialogs().hasOwnProperty( id ) ) {
					const { [ id ]: dialog } = dialogs();

					const debugMessage: string = "Opening dialog '".concat( id.concat( "': " ) );
					console.log( debugMessage, !dialog.open() );


					dialog.setOpen( true );
				}
				else if ( abort )
					throw new Error( "No such dialog named '".concat( id.concat( "' is open." ) ) );
			};

			return handler;
		},

		isOpen ( id, abort: boolean = false ): boolean {
			if ( dialogs().hasOwnProperty( id ) ) {
				const { [ id ]: dialog } = dialogs();
				return dialog.open();
			}
			else if ( abort )
				throw new Error( "No such dialog named '".concat( id.concat( "' is open." ) ) );
			else return false;
		},

		close ( id ): VoidFunction {
			const handler: VoidFunction = () => {
				if ( dialogs().hasOwnProperty( id ) ) {
					const { [ id ]: dialog } = dialogs();
					dialog.setOpen( false );
				}
				else throw new Error( "No such dialog named '".concat( id.concat( "' is open." ) ) );
			};

			return handler;
		}
	};

	return self;
};

export default DrugPricingModelService;
