import type { Component, Accessor } from "solid-js";
import { Grid } from "@suid/material";

import { Collection } from "../../../../forms/Care4You/hipaa/list";
import { Paragraph } from "../../../../forms/Care4You/hipaa/styles";
import Headline from "../../../../forms/Care4You/hipaa/headline";

import type { PersonalForm } from "../../../../forms/Care4You/Personal";
import type { LocationForm } from "../../../../forms/Care4You/Location";
import type { ContactType as ContactForm } from "../../../../forms/Care4You/Contact";

type Props = {
	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
};

const HipaaSection2: Component<Props> = (props) => (
	<>
		<Headline>Section II: Health Information</Headline>

		<Grid xs={12} spacing={0} px={2} container>
			<Grid xs={12}>
				<Paragraph variant="subtitle1">
					I hereby grant the above healthcare Provider(s) permission
					to:
				</Paragraph>
			</Grid>
			<Grid xs={12}>
				<Paragraph sx={{ fontStyle: "italic" }}>
					Disclose my complete health record including, but not
					limited to-
				</Paragraph>
			</Grid>
			<Grid xs={12} px={2}>
				<Collection
					items={[
						"Diagnoses",
						"Lab Test results",
						"Billing records for all conditions",
					]}
				/>
			</Grid>
			<Grid xs={12}>
				<Paragraph fontStyle="italic" variant="subtitle1">
					Forms of disclosure:
				</Paragraph>
			</Grid>
			<Grid xs={12} px={2}>
				<Collection
					items={[
						"Electronic copy",
						"Access via web-based portal",
						"Hard Copy",
					]}
					plain
					bullet
				/>
			</Grid>
		</Grid>
	</>
);

export default HipaaSection2;
