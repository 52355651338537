/// <reference path="../../../../../Modules/Rxapp/resources/ts/types/app.d.ts" />
import { createSignal, createContext } from "solid-js";
import type { Signal, Setter, Context, Accessor, ParentComponent } from "solid-js";

import type { Channel } from "laravel-echo";

import type { PaymentIntent } from '@stripe/stripe-js/dist/api/payment-intents';
import { loadStripe, type Stripe } from "@stripe/stripe-js";

import {
	create as CreateHttpRequest,
	gretch as HttpRequest,
	type GretchResponse as HttpResponse,
	type GretchInstance as RequestInstance,
	type GretchOptions as RequestOptions,
} from "gretchen";

import { Grid, DialogContentText } from "@suid/material";
import MaskedTextField, { MaskedDateField } from '../../helpers/Payment/PaymentInputs';

import { Step, NextEvent } from "../../components/Stepper";
import "./test.scss";

import type { SessionCreated } from "../../forms/Care4You/Welcome";
import type { ContactType as ContactForm } from "../../forms/Care4You/Contact";
import type { PersonalForm } from "../../forms/Care4You/Personal";
import type { LocationForm } from "../../forms/Care4You/Location";

type Props = {
	caption: string, endpoint?: string, name: string, setSecret: Setter<string>,
	secret: Accessor<string>, session: Accessor<SessionCreated>, form: FormType
};

type FormType =
	{
		personal: PersonalForm,
		location: LocationForm,
		contact: ContactForm
	};

type ChannelData = {
	/** Broadcast Event (e.g., FooEvent) */
	event: string,

	/** Broadcast Channel (e.g., my-event, my-event.123) */
	channel: string;
};
type CheckoutMessage = { type: "PendingRequest", detail: ChannelData };

type CheckoutFailure = { type: "RequestFailure", detail: {
	failure: {
		errorCode: number,
		errorText: string
	}
}};

type PaymentResponse = { success: boolean, intent?: PaymentIntent };
type EndpointCallback = (param?: string) => string;

const withEndpoint: EndpointCallback = (arg: string = "authorize") => "/api/v1/payment/".concat(arg);

const PaymentContainer: ParentComponent<Props> = (props) => {
	const echo = window.Echo;
	const [stripe, setStripe]: Signal<Stripe | undefined> = createSignal<Stripe>();

	const [expiration, setExpiration]: Signal<string> = createSignal<string>("");
	const [cardNumber, setCardNumber]: Signal<string> = createSignal<string>("");

	//console.log({ meta: import.meta });

	/*
	onMount(async () => setStripe(
		(await loadStripe(import.meta.VITE_STRIPE_KEY)) as Stripe,
	));
	*/
	//createEffect(() => console.log({ stripe: stripe() }));

	const response: SessionCreated =
		props.session() as unknown as SessionCreated;

	const LogRequestSent = () =>
		console.debug("Sent payment request, waiting for response...");

	const LogResponseError = (message: string) =>
		console.error("Request failure: ".concat( message ));

	const HandlePaymentResponse = (
		channel: Channel,
		event: string,
		setWorking: Setter<boolean>,
		setIndex: Setter<number>,
		index: NextEvent
	) => {
		const paymentEvent: string = '.'.concat(event);

		//props.setResponse(response.data),
		//setIndex((page) => page + 1)

		const handle = (result: PaymentResponse) => {
			console.log("Received payment response.", result);
		};

		channel.listen(paymentEvent, handle);
	};

	return  (
		<Step name={props.name} caption={props.caption}>
			{({ emitter, setIndex, ...stepper }, index) => {
				emitter.on("next", (index) => (
					HttpRequest<CheckoutMessage, CheckoutFailure>(
						withEndpoint(props.endpoint),
						({
							method: "POST",
							headers: {
								Accept: "application/json",
							},
							json: {
								form: {
									personal: props.form.personal,
									location: props.form.location,
									contact: props.form.contact,
								},
								session: response.session,
							},
						})
					)
					.json()
					.then((	{ data }: HttpResponse<CheckoutMessage>) => {
							const response: ChannelData
								= (data as CheckoutMessage).detail;

							const channel = echo
								.private(response.channel)
								.error(LogResponseError)
								.subscribed(LogRequestSent);

							HandlePaymentResponse(
								channel,
								response.event,
								stepper.setWorking,
								setIndex,
								index
							);
						})
					)
				);

				return (
					<DialogContentText>
						<Grid sx={{ padding: 2 }} spacing={2} container>
							<Grid xs={12} md={8}>
								<MaskedTextField
									mask="9999 9999 9999 9999"
									label="Credit Card Number"
									placeholder="9999 9999 9999 9999"
									onChange={setCardNumber}
									appendElement={
										<MaskedDateField
											label="Expiration"
											mask="99 / 99"
											placeholder="01 / 99"
											onChange={setExpiration}
											variant="filled"
											fullWidth
										/>
									}
									fullWidth
								/>
							</Grid>
							<Grid xs={12} md={4}>
								<MaskedTextField
									mask="9990"
									label="CVV/CVV2"
									helperText=""
									placeholder="999"
									fullWidth
								/>
								</Grid>
						</Grid>
					</DialogContentText>
				);
			}}
		</Step>
	);
};

export default PaymentContainer;
