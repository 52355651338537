import { createSignal, type Setter } from 'solid-js';
type SetDialogFn = ( value: boolean ) => VoidFunction;

const RegistrationDlg = (): {
	open: boolean,
	busy: boolean,
	setDialog: SetDialogFn,
	setBusy: Setter<boolean>;
} => {
	const [ waiting, setBusy ] = createSignal<boolean>( false );
	const [ value, setValue ] = createSignal<boolean>( false );

	return ( {
		get open (): boolean {
			return value();
		},

		get busy (): boolean {
			return waiting();
		},

		setDialog: ( value: boolean ) => (
			() => setValue( value )
		),

		setBusy
	} );
};

export default RegistrationDlg;