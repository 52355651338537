import { createSignal, type JSX, type ParentComponent, type Signal, type Accessor } from 'solid-js';
import { flexRender, getCoreRowModel, createSolidTable } from '@tanstack/solid-table';
import { blueGrey } from '@suid/material/colors';
import MenuIcon from '@suid/icons-material/ArrowDropDown';
import ChevronLeft from '@suid/icons-material/ChevronLeft';
import ChevronRight from '@suid/icons-material/ChevronRight';

import type { Theme } from "@suid/material/styles";
import type { ColumnDef, Row } from '@tanstack/solid-table';


import {
	TableContainer, ListItem, ListItemIcon, ListItemText, TableRow, TableBody,
	TableCell, ListItemButton, MenuItem, IconButton, Table, TableHead, Button,
	List, Menu, TableFooter, ButtonGroup, Typography, AppBar, Toolbar,
	useTheme
} from '@suid/material';

export type Drug = {
	DrugName: string,
	NDC: string,
	Strength: string,
	DoseForm: string,
	genericFor: string | false;
};

export type DrugCost = Drug & {
	params: {
		quantity: number;
	},
	cost: {
		value: number;
	};
};

const drugColumns: Array<ColumnDef<Drug>> = [
	{
		accessorKey: 'drugName',

		cell: ( info ) => (
			<TableCell>
				<Typography>{ info.getValue<string>() }</Typography>
			</TableCell>
		),

		header: ( info ) => (
			<TableCell colSpan={ info.header.colSpan } >
				<Typography>Drug Name</Typography>
			</TableCell>
		),
	},
	{
		accessorKey: 'genericFor',

		cell: ( info ) => (
			<TableCell align="right">
				<Typography>{ ( info.getValue<string | false>() === false ) && "N/A" || info.getValue<string>() }</Typography>
			</TableCell>
		),

		header: ( info ) => (
			<TableCell align="right" colSpan={ info.header.colSpan }>
				<Typography>Generic For</Typography>
			</TableCell>
		),
	},
	{
		accessorKey: 'strength',
		cell: ( info ) => (
			<TableCell align="right">
				<Typography>{ info.getValue<string>() }</Typography>
			</TableCell>
		),

		header: ( info ) => (
			<TableCell align="right" colSpan={ info.header.colSpan } >
				<Typography>Strength</Typography>
			</TableCell>
		),
	},
];

const indexOf = ( i: number, pageSize: number ) => Math.floor( i / pageSize );

const paginate = <
	Item extends object = object,
	Items extends Array<Item> = Array<Item>,
	Pages extends Array<Items> = Array<Items>
> (
	items: Items,
	pageSize: number
): Pages => (
	items.reduce<Pages>( ( pages, item, i ) => (
		( pages.length < ( indexOf( i, pageSize ) + 1 ) )
		&& pages.push( [ item ] as Items )
		|| pages[ indexOf( i, pageSize ) ].push( item ),

		pages
	), ( [] as unknown ) as Pages )
);

type DrugClickCb = ( item: Drug ) => unknown;
type DrugSearchProps = {

	/**
	 * On-Click handler for columns
	 */
	onClick?: DrugClickCb,

	/**
	 * Array of columns to display
	 */
	columns?: Array<ColumnDef<any>>,

	/**
	 * Array of items to display
	 */
	items: Accessor<Array<Drug>>;
};

type HandleRowActivate = ( row: Row<Drug> ) => VoidFunction;
type RecordIndex = `${ number }.${ number }`;

type GetRecordIndex<T extends ( string | RecordIndex ) = RecordIndex> = ( row: Row<Drug> ) => T;
type MatchRecordIndex<T extends ( string | RecordIndex ) = RecordIndex> = ( row1: Row<Drug>, index?: T ) => boolean;

const getRecordIndex: GetRecordIndex = <
	T extends ( string | RecordIndex ) = RecordIndex
> ( row: Row<Drug> ): T => `${ row.index }.${ row.depth }` as T;

const matchRecordIndex: MatchRecordIndex = ( record, index ) =>
	index && ( String( index ).localeCompare( getRecordIndex( record ) ) ) === 0 || false;

/**
 * DataGrid component
 */
export const DataGrid: ParentComponent<DrugSearchProps> = ( props ) => {
	const pageSizes: Array<number> = [ 5, 10 ];
	const theme: Theme = useTheme();

	const [ menu, setMenu ]: Signal<boolean> = createSignal<boolean>( false );
	const [ anchor, setAnchor ]: Signal<HTMLElement | undefined> = createSignal<HTMLElement>();

	const [ current, setCurrent ] = createSignal<number>( 0 );
	const [ selected, setSelected ]: Signal<number> = createSignal<number>( 0 );
	const [ selection, setSelection ] = createSignal<RecordIndex>();

	const [ pages, setPages ] = createSignal( paginate( props.items(), pageSizes[ current() ] ) );

	// Re-paginate, based on the user's selection
	const handleSetPageSize = ( index: number, size: number ) => ( () => (
		setSelected( index ),

		setPages( paginate( props.items(), size ) ),
		setMenu( false )
	) );

	const prev = () => setCurrent( ( index: number ) => index - 1 );
	const next = () => setCurrent( ( index: number ) => index + 1 );

	const doPagerMenuActivate: JSX.EventHandlerUnion<HTMLDivElement, MouseEvent> = ( { currentTarget } ) => (
		setAnchor( currentTarget ), setMenu( true )
	);

	const handleRowActivate: HandleRowActivate = ( record: Row<Drug> ) => ( () => {
		setSelection( getRecordIndex( record ) );
		props.onClick?.( record.original );
	} );

	const table = createSolidTable( {
		get data () {
			return ( ( props.items?.() || [] ).length > 0 && (
				pages()[ current() ]
			) ) || [];
		},

		enableRowSelection: true,
		getCoreRowModel: getCoreRowModel(),
		columns: props.columns || drugColumns,
	} );

	const { palette: { primary } } = theme;

	return (
		<TableContainer>
			<Table>
				<TableHead sx={ {
					backgroundColor: blueGrey[ 500 ],
					color: primary.contrastText
				} }>
					{ table.getHeaderGroups().map( ( group ) => (
						<TableRow>
							{ group.headers.map( ( { isPlaceholder, column: { columnDef: column }, getContext } ) => (
								isPlaceholder
								&& (
									<TableCell>
									</TableCell>
								)
								|| flexRender( column.header, getContext() )
							) ) }
						</TableRow>
					) ) }
				</TableHead>
				<TableBody>
					{ table.getRowModel().rows.map( ( row ) => (
						<TableRow
							onClick={ handleRowActivate( row ) }
							sx={ {
								...( matchRecordIndex( row, selection() ) && {
									'& p': {
										color: primary.contrastText,
										fontWeight: 600
									},

									backgroundColor: primary.main,
								} )
							} }
						>
							{ row.getVisibleCells().map( ( { column: { columnDef: column }, getContext } ) => (
								flexRender( column.cell, getContext() )
							) ) }
						</TableRow>
					) ) }
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell sx={ { padding: 0 } } colSpan={ 6 }>
							<AppBar sx={ { minHeight: '48px !important' } } color="transparent" elevation={ 0 } position="static">
								<Toolbar sx={ {
									minHeight: '48px !important',

									paddingLeft: '16px !important',
									paddingRight: '16px !important'
								} }>
									<Typography variant="body1" flexGrow={ 1 }>{ props.children }</Typography>

									<>
										<List component="nav" sx={ { py: 0 } }>
											<ListItem sx={ { py: 0 } }>
												<ListItemText primary='Rows per page:' />

												<ListItemButton sx={ {
													pl: 1.5,
													maxWidth: 48,
													fontSize: '1rem',
												} } onClick={ doPagerMenuActivate }>
													{ String( pageSizes[ selected() ] ) }
													<ListItemIcon sx={ {
														minWidth: undefined,
														maxWidth: "48px",
													} }>
														<MenuIcon sx={ { maxWidth: '16px', maxHeight: '16px' } } />
													</ListItemIcon>
												</ListItemButton>
											</ListItem>
										</List>

										<Menu anchorEl={ anchor() } open={ menu() } onClose={ () => setMenu( false ) } >
											{ pageSizes.map( ( pageSize: number, index: number ) => (
												<MenuItem onClick={ handleSetPageSize( index, pageSize ) }>
													{ String( pageSize ) }
												</MenuItem>
											) ) }
										</Menu>
									</>

									<ButtonGroup>
										<IconButton disabled={ current() === 0 } onClick={ prev }>
											<ChevronLeft />
										</IconButton>
										{ pages().map( ( page, index: number ) => (
											<Button disabled={ 1 === pages().length }
												onClick={ () => setCurrent( () => index ) }
												variant={ ( index === current() && 'contained' ) || 'text' }
											>
												{ index + 1 }
											</Button>
										) ) }
										<IconButton
											disabled={ current() + 1 === pages().length }
											onClick={ next }
										>
											<ChevronRight />
										</IconButton>
									</ButtonGroup>
								</Toolbar>
							</AppBar>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};
