import { createSignal, splitProps } from "solid-js";
import type { JSX, Signal, Setter, Component } from "solid-js";

import {
	FilledInput, InputLabel,
	FormControl, Typography,
	FormHelperText, Stack
} from "@suid/material";

import type { FilledInputProps } from '@suid/material/FilledInput/FilledInputProps';
import { createInputMask } from "@solid-primitives/input-mask";

import type { CreditCardType as CardType } from "credit-card-type/src/types";
import creditCardType from "credit-card-type";

export { default as Images } from "./Methods";

type MaskedTextFieldBase = Omit<FilledInputProps, "onChange" | "value"> & {
	mask: string,
	type?: FilledInputProps["type"],
	value?: string,
	label?: string,
	variant?: 'outlined' | 'filled',
	onChange?: Setter<string>,
	helperText?: string,
	placeholder?: FilledInputProps["placeholder"],
};

type MaskedTextFieldProps = MaskedTextFieldBase & { appendElement?: JSX.Element };
type MaskedInputEvent<T = unknown> = (ev: ClipboardEvent | KeyboardEvent | InputEvent) => T;

type MaskedInputEventType<T = void> = (onInput: MaskedInputEvent) => MaskedInputEvent<T>;

export const MaskedDateField: Component<MaskedTextFieldBase> = (args) => {
	const [
		{ label, onChange, mask, placeholder, helperText },
		{ value: v = "", ...props },
	] = splitProps(args, [ "onChange", "mask", "helperText", "placeholder", "label" ]);

	const dateMask = createInputMask(mask);
	const [, setContent] = createSignal(v);

	const onInputEvent: MaskedInputEventType = (onInput) => (
		(ev: ClipboardEvent | KeyboardEvent | InputEvent): void => {
			const value: string = setContent(dateMask(ev));
			onInput(ev);

			onChange?.(value);
		}
	);

	return (
		<FormControl sx={{ my: 1 }} variant="filled" fullWidth>
			<FilledInput
				id="formatted-input-field"
				inputComponent={({ onInput, ...field }) => (
					<input
						type="text"
						onInput={onInputEvent(onInput)}
						onPaste={onInputEvent(onInput)}
						{...field}
					/>
				)}
				placeholder={placeholder}
				{...props}
			/>

			<InputLabel for="formatted-input-field" shrink>
				{label}
			</InputLabel>
			<FormHelperText id="formatted-input-field-text">
				{helperText}
			</FormHelperText>
		</FormControl>
	);
};

const MaskedTextField: Component<MaskedTextFieldProps> = (params) => {
	const [
		{ mask, onChange, appendElement, label, placeholder, ...rest },
		{ value: v = "", ...props }
	] = splitProps(params, [
		"helperText", "placeholder", "type",
		"onChange", "appendElement", "mask",
		"label"
	]);

	const textMask = createInputMask(mask);
	const [content, setContent]: Signal<string> = createSignal<string>(v);

	const [helperText, setHelperText]: Signal<undefined | string>
		= createSignal<undefined | string>(rest.helperText);

	const hasHelperText: boolean = rest.helperText !== undefined;

	const defaultCardTypeArgs: Array<CardType> = [
		({ niceType: undefined } as unknown) as CardType
	];

	const onInputEvent: MaskedInputEventType = (onInput) => (
		(ev: ClipboardEvent | KeyboardEvent | InputEvent): void => {
			const value: string = setContent(textMask(ev));

			if (!hasHelperText) {
				const [card]: Array<CardType> =
					creditCardType(value.substring(0, 3)) ??
					defaultCardTypeArgs;

				setHelperText(card && card.niceType);
			}

			onInput(ev);
			onChange?.(value);
		}
	);

	return (appendElement && (
		<Stack direction={"row"}>
			<FormControl
				sx={{ my: 1 }}
				variant="filled"
				error={(
					content().length>3 && !!!helperText()
				)}
				fullWidth
			>
				<FilledInput
					id="formatted-input-field"
					inputComponent={({ onInput, ...field }) => (
						<input
							onInput={onInputEvent(onInput)}
							onPaste={onInputEvent(onInput)}
							{...field}
						/>
					)}

					placeholder={placeholder}
					{...props}
				/>

				<InputLabel for="formatted-input-field" shrink>
					{label}
				</InputLabel>
				<FormHelperText id="formatted-input-field-text">
					{( ( hasHelperText &&
						<Typography sx={{ display: 'inline' }}>{rest.helperText}</Typography>
					) || (helperText()) && (
						<>
							<Typography sx={{ display: 'inline' }} fontWeight={600}>Selected</Typography>:
							<Typography sx={{ display: 'inline' }}>{helperText()}</Typography>
						</>
					) || ((content().length>3 && !!!helperText()) &&
						<>
							Invalid Credit Card Number
						</>
					))}
				</FormHelperText>
			</FormControl>
			{appendElement}
		</Stack>
	)
	|| (
		<FormControl sx={{ my: 1 }} variant="filled" fullWidth>
			<FilledInput
				id="formatted-input-field"
				inputComponent={({ onInput, ...field }) => (
					<input
						onInput={onInputEvent(onInput)}
						onPaste={onInputEvent(onInput)}
						{...field}
					/>
				)}

				placeholder={placeholder}
				{...props}
			/>

			<InputLabel for="formatted-input-field" shrink>
				{label}
			</InputLabel>
			<FormHelperText id="formatted-input-field-text">
				{( ( hasHelperText &&
					<Typography sx={{ display: 'inline' }}>{rest.helperText}</Typography>
				) || (helperText()) && (
					<>
						<Typography sx={{ display: 'inline' }} fontWeight={600}>Selected</Typography>:
						<Typography sx={{ display: 'inline' }}>{helperText()}</Typography>
					</>
				) || (((content().length > 3) && !!!helperText()) &&
					<>
						Invalid Credit Card Number
					</>
				))}
			</FormHelperText>
		</FormControl>
	));
};

export default MaskedTextField;
