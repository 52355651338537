import { isMobile } from "@solid-primitives/platform";
import {
	Fade,
	DialogContent,
	Grid,
	Button,
	Typography,
	DialogActions,
} from "@suid/material";
import type { ParentComponent, Accessor } from "solid-js";
import { StringFmt } from "../../helpers/Format";
import { Step } from "../../components/Stepper";
import type { SessionCreated } from "../../forms/Care4You/Welcome";
import type { EnrollResponse } from "../../forms/Care4You/Consent";

type Props = {
	response: Accessor<EnrollResponse>;
	session: Accessor<SessionCreated>;
};

const route = (
	{ token, params }: EnrollResponse,
	download: boolean = false,
): string =>
	(
		(download &&
			StringFmt(
				"/api/v1/member/pdf/%s/download?expires=%d&signature=%s",
			)) ||
		StringFmt("/api/v1/member/pdf/%s?expires=%d&signature=%s")
	).format(token, params.expires, params.signature);

const handleClick = (uri: string) => () =>
	window?.open?.(uri, "_blank")?.focus?.();

const ThankYouForm: ParentComponent<Props> = (props) => (
	<Step name="thankyou" caption="Registration Complete">
		{(stepper, index) => (
			<>
				<Fade in={stepper.index() === index}>
					<DialogContent>
						<Grid spacing={2} container>
							{props.children && (
								<Grid xs={12} item>
									{props.children}
								</Grid>
							)}
							<Grid xs={12} item>
								<Typography
									color="green"
									variant="h2"
									fontSize={21}
									fontWeight={700}
								>
									Thank you!
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography fontSize={21} textAlign="justify">
									You have been successfully enrolled in the{" "}
									{props.session().details.name} program!
								</Typography>
							</Grid>
							<Grid xs={12} item>
								<Typography fontSize={21} textAlign="justify">
									Your Member Identification card is ready to
									be downloaded! Simply{" "}
									{(isMobile && "tap") || "click"} the button
									below, and your browser will automatically
									download your Program Identification Card!
								</Typography>
							</Grid>
						</Grid>
					</DialogContent>
				</Fade>
				<DialogActions>
					<Grid spacing={2} container>
						<Grid xs={12} item>
							<Button
								variant="contained"
								onClick={handleClick(route(props.response()))}
								fullWidth
							>
								View my membership card
							</Button>
						</Grid>
						<Grid xs={12} item>
							<Button
								variant="contained"
								onClick={handleClick(
									route(props.response(), true),
								)}
								fullWidth
							>
								Download my membership card
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</>
		)}
	</Step>
);

export default ThankYouForm;
