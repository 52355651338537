import type { Component, Accessor } from "solid-js";
import { Grid, TextField } from "@suid/material";

import { Paragraph, Subject } from "../styles";
import Headline from "../../../../forms/Care4You/hipaa/headline";

import type { PersonalForm } from "../../../../forms/Care4You/Personal";
import type { LocationForm } from "../../../../forms/Care4You/Location";
import type { ContactType as ContactForm } from "../../../../forms/Care4You/Contact";

type Props = {
	form: {
		personal: Accessor<PersonalForm>;
		location: Accessor<LocationForm>;
		contact: Accessor<ContactForm>;
	};
};

const HipaaSection1: Component<Props> = (props) => (
	<>
		<Headline>Section I</Headline>

		<Grid xs={12} px={2} spacing={0} container>
			<Grid
				xs={1}
				md={1}
				sx={{
					display: "flex",
					justifyContent: "start",
					alignItems: "center",
				}}
				item
			>
				<Paragraph variant="subtitle1">I,</Paragraph>
			</Grid>
			<Grid xs={11} md={8} sx={{ display: { xs: "flex", md: "block" } }}>
				<TextField
					name="PatientName"
					variant="standard"
					value={props.form
						.personal()
						.first?.concat(
							" ".concat(
								(props.form.personal().middle || " ")?.concat(
									props.form.personal()?.last as string,
								),
							),
						)}
					InputProps={{
						readOnly: true,
					}}
					hiddenLabel
					fullWidth
				/>
				<Paragraph
					sx={{
						lineHeight: { xs: 3, md: 0 },
						display: { xs: "inline", md: "none" },
					}}
				>
					,
				</Paragraph>
			</Grid>
			<Grid
				xs={12}
				md={3}
				sx={{
					display: { xs: "block", md: "flex" },
					justifyContent: { xs: undefined, md: "end" },
					alignItems: { xs: undefined, md: "center" },
				}}
				item
			>
				<Paragraph
					variant="subtitle1"
					sx={{ display: { xs: "none", md: "block" } }}
				>
					hereby grant authorization to
				</Paragraph>
				<Paragraph
					variant="subtitle1"
					sx={{ display: { xs: "inline-block", md: "none" } }}
				>
					hereby grant authorization to{" "}
					<Subject>Care4You Network Providers</Subject> to render
					treatment, and to share the information outlined in{" "}
					<Subject>Section II</Subject> of this document, with the
					person(s) or organization(s) I have specified in{" "}
					<Subject>Section IV</Subject> of this document.
				</Paragraph>
			</Grid>
			<Grid xs={12} sx={{ display: { xs: "none", md: "inline" } }}>
				<Paragraph variant="subtitle1">
					<Subject>Care4You Network Providers</Subject> to render
					treatment, and to share the information outlined in{" "}
					<Subject>Section II</Subject> of this document, with the
					person(s) or organization(s) I have specified in{" "}
					<Subject>Section IV</Subject> of this document.
				</Paragraph>
			</Grid>
		</Grid>
	</>
);

export default HipaaSection1;
